import { inject, Injectable, signal } from '@angular/core';
import { IPermission } from '../../employees/core/interfaces';
import { CurrentUserStoreService } from './CurrentUserStoreService';

@Injectable({
  providedIn: 'root'
})
export class PermissionsStoreService {

  private readonly _currentUserStoreService = inject(CurrentUserStoreService);

  public currentUser = this._currentUserStoreService.currentUser;

  public readonly permissions = signal<IPermission[]>(this.currentUser.permissions.permissions);
}
