import { inject, Injectable } from '@angular/core';
import { PermissionsStoreService } from './PermissionsStoreService';
import { PermissionTypes, PermissionActions } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private readonly _permissionsStoreService = inject(PermissionsStoreService);
  private permissionActions = PermissionActions;

  public hasAccess(permissionType: PermissionTypes, action: PermissionActions): boolean {
    return !!(
      permissionType &&
      action &&
      this._permissionsStoreService
        .permissions()
        .find(x => x.permission.toLowerCase() === permissionType && x.actions.includes(action))
    );
  }

  public hasAnyAccess(permissionType: PermissionTypes): boolean {
    return (
      this.hasAccess(permissionType, this.permissionActions.READ) || this.hasAccess(permissionType, this.permissionActions.EDIT)
    )
  }
}
