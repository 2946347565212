import { Pipe, PipeTransform } from '@angular/core';
import { IDictionaryValue } from '../interfaces';

@Pipe({
  name: 'dictionaryValue'
})
export class DictionaryValuePipe implements PipeTransform {

  public transform(id: number, dictionary: IDictionaryValue[]): string {
    if (dictionary) {
      return dictionary.find(dct => +dct.id === +id)?.name;
    } else {
      return '';
    }
  }
}

