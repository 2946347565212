import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './core/pipes/translate.pipe';
import { LangUrlPipe } from './core/pipes/lang-url.pipe';

@NgModule({
  declarations: [
    TranslatePipe,
    LangUrlPipe
  ],
  exports: [
    TranslatePipe,
    LangUrlPipe
  ],
  imports: [
    CommonModule,
  ]
})
export class LanguageModule { }
