import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToPercent',
})
export class NumberToPercentPipe implements PipeTransform {

  public transform(value: number): string {
    return value.toPercent();
  }
}
