import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  public transform(value: any[], key: string, filterValue: any): any[] {
    return value?.length ? value.filter(x => x[key] === filterValue) : [];
  }
}
