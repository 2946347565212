import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

  public transform(value: any): number {
    return new Date(value).getTime();
  }
}
