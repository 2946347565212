import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {

  public transform(minutes: number): string {
    return `${ Math.floor(minutes / 60).toString().padStart(2, '0') }:${ (minutes % 60).toString().padStart(2, '0') }`;
  }
}
