import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'find'
})
export class FindPipe implements PipeTransform {

  public transform<T>(value: T[], key: string, filterValue: any): T | null {
    return value?.length ? value.find(x => x[key] === filterValue) : null;
  }
}
