{
  "buttons/add.Label": "Hinzufügen",
  "buttons/cancel.Label": "Abbrechen",
  "buttons/delete.Label": "Löschen",
  "buttons/create.Label": "Erstellen",
  "buttons/save.Label": "Speichern",
  "buttons/confirm.Label": "Bestätigen",
  "buttons/search.Label": "Suche",
  "buttons/continue.Label": "Weiter",
  "buttons/send.Label": "Senden",
  "buttons/close.Label": "Schließen",
  "buttons/back.Label": "Zurück",
  "buttons/reset.Label": "Rücksetzen",

  "labels/search.Label": "Suchen",
  "labels/pagination/showResults.Label": "Resultate anzeigen",
  "input/search.Placeholder": "Suchen Sie nach Ihrer Phrase",
  "titles/create-new.Label": "Neu erstellen",
  "titles/edit.Label": "Bearbeiten",
  "titles/image.Placeholder":  "Bild hinzufügen",
  "labels/tables.Loading": "Ergebnisse laden...",
  "labels/tables.NoResults": "Keine Ergebnisse",
  "labels/tables.MoreNoResults": "Es gibt keine Daten zur Vorschau.",
  "labels/tables.ChangePhrase": "Bitte ändern Sie den Suchbegriff.",
  "labels/tables.ResetFilters": "filter zurücksetzen",

  "forms/required.Label": "Pflichtfeld",
  "forms/nonEditableFieldTooltip.Text": "Nicht editierbar - bitte kontaktieren Sie den cinetixx Support, um diese Optionen zu ändern."
}
