import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LanguageStoreService } from '@cinetixx/cinetixx-ui';


export const NoLangGuard: CanActivateFn = (): boolean => {
  location.href = `/${ inject(LanguageStoreService).language?.token ?? environment.fallbackLanguage }`;

  return true;
};
