import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-planning-scaling-buttons',
  templateUrl: './planning-scaling-buttons.component.html',
  styleUrls: ['./planning-scaling-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanningScalingButtonsComponent {

  @Input() isFitToWindowActive = false;

  @Output() zoomChanged = new EventEmitter<number>();
  @Output() resetZoom = new EventEmitter<void>();
  @Output() fitToWindow = new EventEmitter<void>();
}
