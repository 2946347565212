import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionTypes, PermissionActions } from '../enums';
import { PermissionsService } from '../services/PermissionsService';
import { AlertComponent, AlertTypes } from '@cinetixx/cinetixx-ui';

@Directive({
  selector: '[appShowForPermission]'
})
export class ShowForPermissionDirective {

  @Input() set appShowForPermission(
    [
      permissionType,
      permissionAction,
      showAlert
    ]: [keyof typeof PermissionTypes, keyof typeof PermissionActions, boolean?]
  ) {
    if (
      this._permissionsService.hasAccess(PermissionTypes[permissionType], PermissionActions[permissionAction]) &&
      !this._isCreated
    ) {
      this._isCreated = true;
      this._viewContainer.createEmbeddedView(this._templateRef);
    } else {
      this._isCreated = false;
      this._viewContainer.clear();

      if (showAlert) {
        const alertRef = this._viewContainer.createComponent(AlertComponent);
        alertRef.instance.type = AlertTypes.ERROR;
        alertRef.instance.title = 'Error';

        alertRef.instance.message = 'You do not have permission to access this.'.trans('alerts/access-denied.Text');
        alertRef.instance.alertStyle = 'multiple';
        alertRef.location.nativeElement.classList.add('m-y-16', 'd-block');
      }
    }
  };

  private _isCreated = false;
  private readonly _templateRef = inject(TemplateRef);
  private readonly _viewContainer = inject(ViewContainerRef);
  private readonly _permissionsService = inject(PermissionsService);
}
