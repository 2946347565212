export enum Actions {
  ADD_SCANNER = 'add_scanner',
  PLANNING = 'planning',
  REPORTS = 'reports',
  MARKETING = 'marketing',
  ACTIVATION_ORDERS = 'activation_orders',
  INVOICES = 'invoices',
  FFA = 'ffa',
  ADD_EMPLOYEES = 'add_employees',
  EDIT_EMPLOYEES = 'edit_employees',
  EDIT_ACCOUNT_DETAILS = 'edit_account_details',
  CONTROLLING = 'controlling',
  BUTTON_RESEND_EMAILS = 'button_resend_emails',
  TSE_DEVICES = 'tse_devices',
  SCANNER_DEVICES = 'scanner_devices',
  TERMINALS = 'terminals',
  AUDITORIUMS = 'auditoriums'
}
