import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isset'
})
export class IssetPipe implements PipeTransform {

  public transform(value: any): boolean {
    return value !== null && value !== undefined;
  }
}
