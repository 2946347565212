import { ChangeDetectorRef, Component, inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { IdentityService } from './core/services/IdentityService';
import { AuthService } from './auth/core/services/AuthService';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { NavigationService, NewVersionModalComponent, StaticDependenciesService } from '@cinetixx/cinetixx-ui';
import { interval } from 'rxjs';
import { languagesSnippets$ } from './language/core/resolvers/languages-snippets.resolver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('newVersionModalComponent') newVersionModalComponent: NewVersionModalComponent;

  public isLoaderVisible = true;
  public isNewVersionModalVisible = false;

  private readonly _identityService = inject(IdentityService);
  private readonly _authService = inject(AuthService);
  private readonly _navigationService = inject(NavigationService);
  private readonly _router = inject(Router);
  private readonly _swUpdate = inject(SwUpdate);
  private readonly _cdr = inject(ChangeDetectorRef);
  private readonly _ngZone = inject(NgZone);

  public ngOnInit(): void {
    const reloadPageTimeout = setTimeout(() => {
      if (StaticDependenciesService.document.readyState !== 'complete') {
        window.location.reload();
      }
    }, 20000);

    this._router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        clearTimeout(reloadPageTimeout);
      }

      if (environment.production && event instanceof NavigationEnd) {
        const fCookie = (
          `; ${ StaticDependenciesService.document.cookie }`
        ).split(`; cookiesPreferences=`).pop().split(';')[0];

        if (fCookie && JSON.parse(fCookie).analyticsCookie) {
          window.dataLayer.push({
            'event':'cinetixx_manager_allowed',
            'page':{
              'url': event.urlAfterRedirects
            }
          });
        }
      }
    });

    languagesSnippets$.subscribe(() => {
      this.isNewVersionModalVisible = true;
      this._cdr.detectChanges();
    });

    if (this._identityService.identity) {
      this._authService.getStatus().subscribe({
        error: () => {
          this._identityService.destroyIdentity();
          this._navigationService.navigateByUrl('/auth/login');
        }
      });
    }

    if (this._swUpdate.isEnabled) {
      this._ngZone.runOutsideAngular(() => {
        const interval$ = interval(1000 * 10).subscribe(() => {
          this._swUpdate.checkForUpdate().then(isNewVersion => {
            console.log('isNewVersion', isNewVersion);

            if (isNewVersion) {
              interval$.unsubscribe();
              this.newVersionModalComponent.open();
            }
          });
        })
      });

      this._swUpdate.versionUpdates.subscribe(x => console.log(x));
      this._swUpdate.versionUpdates.pipe(
        filter(event => event.type === 'VERSION_READY')
      ).subscribe(() => this.newVersionModalComponent.open());
    }
  }
}
