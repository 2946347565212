{
  "buttons/add.Label": "Add",
  "buttons/cancel.Label": "Cancel",
  "buttons/delete.Label": "Delete",
  "buttons/create.Label": "Create",
  "buttons/save.Label": "Save",
  "buttons/confirm.Label": "Confirm",
  "buttons/close.Label": "Close",
  "buttons/search.Label": "Search",
  "buttons/send.Label": "Send",
  "buttons/continue.Label": "Continue",
  "buttons/back.Label": "Back",
  "buttons/reset.Label": "Reset",

  "labels/search.Label": "Search",
  "labels/pagination/showResults.Label": "Show Results",
  "input/search.Placeholder": "Search by your phrase",
  "titles/create-new.Label": "Create new",
  "titles/edit.Label": "Edit",
  "titles/image.Placeholder":  "Add image",
  "labels/tables.Loading": "Loading results...",
  "labels/tables.NoResults": "No results",
  "labels/tables.MoreNoResults": "There is no data to preview.",
  "labels/tables.ChangePhrase": "Please change the search phrase.",
  "labels/tables.ResetFilters": "reset filters",

  "forms/required.Label": "Mandatory field",
  "forms/nonEditableFieldTooltip.Text": "Not editable — please contact cinetixx Support to change those options."
}
