<div class="d-flex align-items-center gap-8">
  <button
    class="icon-button sm"
    (click)="zoomChanged.emit(1)"
    cxTooltip
    [content]="'Vergrößern' | trans:'screener/planning/basic-settings/zoomIn.Text'"
  >
    <i class="ri-zoom-in-line"></i>
  </button>

  <button
    class="icon-button sm"
    (click)="zoomChanged.emit(-1)"
    [disabled]="isFitToWindowActive"
    cxTooltip
    [content]="'Verkleinern' | trans:'screener/planning/basic-settings/zoomOut.Text'"
  >
    <i class="ri-zoom-out-line"></i>
  </button>

  <button
    class="icon-button sm"
    (click)="fitToWindow.emit()"
    cxTooltip
    [content]="'Anpassung an das Fenster' | trans:'screener/planning/basic-settings/fitToWindow.Text'"
  >
    <i class="ri-aspect-ratio-line"></i>
  </button>

  <button
    class="icon-button sm"
    cxTooltip
    [content]="'Fenster zurücksetzen' | trans:'planning/basic-settings/resetZoom.Text'"
    (click)="resetZoom.emit()"
  >
    <i class="ri-refresh-line"></i>
  </button>
</div>
