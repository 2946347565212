import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToEuro',
})
export class NumberToEuroPipe implements PipeTransform {

  public transform(
    value: number,
    withOptions = true,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
  ): string {
    return value.toEuro(withOptions, minimumFractionDigits, maximumFractionDigits);
  }
}
