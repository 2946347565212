import { Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isSameOrBefore'
})
export class IsSameOrBeforePipe implements PipeTransform {

  public transform(value: Date | moment.Moment | string, dateToCompare: Date | moment.Moment | string): boolean {

    return moment(value).startOf('day').isSameOrBefore(moment(dateToCompare).startOf('day'));
  }
}
