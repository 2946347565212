import { Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'string'
})
export class StringPipe implements PipeTransform {

  public transform(value: unknown): string {
    return value?.toString();
  }
}
