import { inject, Injectable } from '@angular/core';
import { IdentityService } from './IdentityService';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private readonly _identityService = inject(IdentityService);

  public get token(): string {
    return this._identityService.identity?.accessToken;
  }

  public get refreshToken(): string | null {
    return this._identityService.identity?.refreshToken;
  }
}
