import { inject, Injectable } from '@angular/core';
import { UserPreferencesService } from '../services/UserPreferencesService';
import { ITableState, ITableStateStrategy } from '@cinetixx/cinetixx-ui';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableStateDatabaseStrategy implements ITableStateStrategy {

  private readonly _keyPrefix = 'table-state';

  private readonly _userPreferencesService = inject(UserPreferencesService);

  public getState(namespace: string): Observable<ITableState> {
    return this._userPreferencesService.getPreferenceByKey(this.getKey(namespace));
  }

  public saveState(namespace: string, state: ITableState): Observable<any> {
    return this._userPreferencesService.savePreferences(this.getKey(namespace), state);
  }

  private getKey(namespace: string): string {
    return `${this._keyPrefix}-${namespace}`;
  }
}
