export class EventUtils {

  private static _lastClickTime = 0;
  private static _lastX: number = null;
  private static _lastY: number = null;

  public static isDoubleClick(event: MouseEvent): boolean {
    const now = new Date().getTime();
    const timespan = now - this._lastClickTime;
    
    const isWithinTimeThreshold = timespan < 400;
    const isWithinPositionThreshold = this._lastX !== null && 
      Math.abs(event.clientX - this._lastX) <= 5 && 
      Math.abs(event.clientY - this._lastY) <= 5;

    const isDoubleClick = isWithinTimeThreshold && isWithinPositionThreshold;

    if (isDoubleClick) {
      this._lastClickTime = 0;
      this._lastX = null;
      this._lastY = null;
    } else {
      this._lastClickTime = now;
      this._lastX = event.clientX;
      this._lastY = event.clientY;
    }

    return isDoubleClick;
  }
}
