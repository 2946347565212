import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToNotation',
})
export class NumberToNotationPipe implements PipeTransform {

  public transform(value: number): string {
    return value.toNotation();
  }
}
