export class HeaderUtils {
  public static getFileNameFromContentDisposition(contentDisposition: string): string {
    const utfMatch = /filename[*]=UTF-8''((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    const match = utfMatch ?? /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);

    if (match && match[1]) {
      return decodeURIComponent(match[1].replace(/['"]/g, ''))
      .replace('.pdf','').replace('.zip','');
    }

    return '';
  }
}
