import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langUrl'
})
export class LangUrlPipe implements PipeTransform {

  public transform(defaultValue: string): string {
    return defaultValue.langUrl();
  }
}
