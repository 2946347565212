import { Injectable } from '@angular/core';
import { LogAsService } from '../../auth/core/services/LogAsService';
import { forkJoin, Observable, of } from 'rxjs';
import { IdentityService } from './IdentityService';
import { NavigationService } from '@cinetixx/cinetixx-ui';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public constructor(
    private readonly _logAsService: LogAsService,
    private readonly _identityService: IdentityService,
    private readonly _navigationService: NavigationService
  ) {
  }

  public loadApp(): Observable<boolean[]> {
    const url = new URL(location.href);
    const identity = url.searchParams.get('identity');

    if (identity) {
      this._identityService.identity = JSON.parse(atob(identity));
    }

    return forkJoin([
      this.checkLogAs(),
      this.checkActivateScreenUrl()
    ]);
  }

  private checkLogAs(): Observable<boolean> {
    const token = new URL(location.href).href.split('/').slice(-1)[0];

    if (location.href.includes('logas') && token) {
      return this._logAsService.logAs(token);
    }

    return of(true);
  }

  private checkActivateScreenUrl(): Observable<boolean> {
    if (location.href.includes('activate-screen')) {
      this._navigationService.navigateByUrl(`/tube/streams?streamGroupId=all&addDevice=true`);
    }

    return of(true);
  }
}
