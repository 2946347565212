import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreCacheKeys, Apps } from '../../../core/enums';
import { HttpParams } from '@angular/common/http';
import { AbstractRestService } from 'src/app/core/services/AbstractRestService';
import { Cache } from '../../../core/decorators/cache.decorator';
import { ILanguage, ISnippet, LanguageStoreService } from '@cinetixx/cinetixx-ui';

@Injectable({
  providedIn: 'root'
})
export class SnippetsService extends AbstractRestService<ISnippet>{

  protected readonly basePath = '/snippets';

  private readonly _languageStoreService = inject(LanguageStoreService);

  @Cache<ILanguage[]>(StoreCacheKeys.GetAllLanguages)
  public getAllLanguages(): Observable<ILanguage[]> {
    return this.apiService.get(`${ this.basePath }/languages/all`);
  }

  @Cache<ISnippet[]>(StoreCacheKeys.GetAllSnippets)
  public getAllSnippets(language: string): Observable<ISnippet[]> {
    return this.apiService.get(
      `${ this.basePath }/all`,
      new HttpParams().append('language', language).append('app', Apps.Manager)
    );
  }

  public addSnippet(namespace: string, value: string): Observable<ISnippet> {
    return this.apiService.post(
      `${ this.basePath }`,
      {
        namespace,
        value,
        snippetLanguageId: this._languageStoreService.language.id,
        appId: Apps.Manager
      }
    );
  }

}
