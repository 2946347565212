import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StaticDependenciesService } from '../services/StaticDependenciesService';
import { StoreCacheKeys } from '../enums';

type Descriptor<T> = TypedPropertyDescriptor<(...args: any[]) => Observable<T>>;

export function Cache<T>(key: StoreCacheKeys): (target: object, propertyKey: string, descriptor: Descriptor<T>) => Descriptor<T> {

  return (target: object, propertyKey: string, descriptor: Descriptor<T>): Descriptor<T> => {

    const modifiedPropertyKey = key + 'Cache$';
    const originalMethod: (...args: any[]) => Observable<T> = descriptor.value!;

    descriptor.value = function(...args: any[]): Observable<T> {

      const storeService = StaticDependenciesService.storeService;

      StaticDependenciesService.identityService.isAuthenticated$.subscribe(isAuthenticated => {
        if (!isAuthenticated && storeService.constructor.prototype[modifiedPropertyKey]) {
          delete storeService.constructor.prototype[modifiedPropertyKey];
        }
      });

      if (storeService[modifiedPropertyKey]) {
        return storeService[modifiedPropertyKey];
      } else {
        return originalMethod.call(this, ...args).pipe(
          tap(x => storeService.constructor.prototype[modifiedPropertyKey] = of(x))
        );
      }
    };

    return descriptor;
  };
}
