import { inject, Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IdentityService } from '../../../core/services/IdentityService';
import { AuthService } from './AuthService';
import { LocalStorageService, NavigationService } from '@cinetixx/cinetixx-ui';


@Injectable({
  providedIn: 'root'
})
export class LogAsService {

  private readonly _authService = inject(AuthService);
  private readonly _navigationService = inject(NavigationService);
  private readonly _identityService = inject(IdentityService);
  private readonly _localStorageService = inject(LocalStorageService);

  public logAs(token:string): Observable<boolean> {
     return this._authService.authWithToken(token)
       .pipe(
         map(identity => {
           this._localStorageService.clear();
           this._identityService.identity = identity;
           this._navigationService.navigateByUrl('/dashboard');

           return of(true);
         }),
         catchError(() => {
          this._identityService.destroyIdentity();
          this._navigationService.navigateByUrl('/auth/login');

          return of(false);
        })
       ) as Observable<boolean>;
  }
}
