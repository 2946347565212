import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trans'
})
export class TranslatePipe implements PipeTransform {

  public transform(defaultValue: string, namespace: string, replacements: [string, string | number][] = []): string {
    return defaultValue.trans(namespace, replacements);
  }
}
