import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PlanningScalingButtonsComponent } from './ui/planning-scaling-buttons/planning-scaling-buttons.component';
import { LanguageModule } from '../language/language.module';
import { TooltipModule } from '@cinetixx/cinetixx-ui';
import { PreloadImageDirective } from './directives/preload-image.directive';
import { ShowAuthedDirective } from './directives/show-authed.directive';
import { UrlTrailerPipe } from './pipes/url-trailer.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { AreaSelectDirective } from './directives/area-select.directive';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { DateTimeFormatPipe } from './pipes/datetime-format.pipe';
import { NumberToNotationPipe } from './pipes/number-to-notation.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { NumberToEuroPipe } from './pipes/number-to-euro.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { StringPipe } from './pipes/string.pipe';
import { NumberToPercentPipe } from './pipes/number-to-percent.pipe';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { IsSameOrBeforePipe } from './pipes/is-same-or-before.pipe';
import { MinutesToTimePipe } from './pipes/minutes-to-time.pipe';
import { IssetPipe } from './pipes/isset.pipe';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { FindPipe } from './pipes/find.pipe';
import { ShowForPermissionDirective } from './directives/show-for-permission.directive';

@NgModule({
  declarations: [
    NumberToNotationPipe,
    DateTimeFormatPipe,
    DateFormatPipe,
    TimeFormatPipe,
    NumberToEuroPipe,
    ReplacePipe,
    PreloadImageDirective,
    PreloadImageDirective,
    ShowAuthedDirective,
    StringPipe,
    UrlTrailerPipe,
    FilterPipe,
    AreaSelectDirective,
    NumberToPercentPipe,
    TimestampPipe,
    PlanningScalingButtonsComponent,
    IsSameOrBeforePipe,
    MinutesToTimePipe,
    IssetPipe,
    FindPipe,
    ShowForPermissionDirective,
  ],
  imports: [
    CommonModule,
    LanguageModule,
    TooltipModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ],
  exports: [
    DateTimeFormatPipe,
    DateFormatPipe,
    NumberToEuroPipe,
    TimeFormatPipe,
    ReplacePipe,
    PreloadImageDirective,
    ShowAuthedDirective,
    StringPipe,
    FilterPipe,
    UrlTrailerPipe,
    AreaSelectDirective,
    NumberToPercentPipe,
    TimestampPipe,
    NumberToNotationPipe,
    PlanningScalingButtonsComponent,
    IsSameOrBeforePipe,
    MinutesToTimePipe,
    IssetPipe,
    FindPipe,
    ShowForPermissionDirective,
  ]
})
export class CoreModule {
}
