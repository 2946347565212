import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { catchError, Observable, Subject, takeUntil, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppVersionStoreService } from '../services/AppVersionStoreService';
import { ActivationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

  private readonly _pendingHTTPRequest$ = new Subject<void>();
  private readonly _excludedApiPathsFromCanceling: string[] = [
    '/employees/logout',
    '/employees/status',
    '/employees/refresh-token',
  ];

  public constructor(
    private readonly _appVersionStoreService: AppVersionStoreService,
    private readonly _router: Router
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this._pendingHTTPRequest$.next();
      }
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const obj = {
      headers: req.headers
        .set('X-app', environment.app)
        .set('x-version-prefix', environment.versionPrefix)
        .set('x-gui-version', environment.version)
    };

    let request$ = next.handle(req.clone(obj));

    if (!this._excludedApiPathsFromCanceling.find(x => req.url.includes(x))) {
      request$ = request$.pipe(takeUntil(this._pendingHTTPRequest$.asObservable()));
    }

    return request$.pipe(
      tap(data => {
        if (data instanceof HttpResponse) {
          this.setVersion(data);
        }
      }),
      catchError(data => {
        if (data instanceof HttpErrorResponse) {
          this.setVersion(data);
        }

        return throwError({
          ...data,
          requestMethod: req.method
        });
      })
    );
  }

  private setVersion(data: HttpErrorResponse | HttpResponse<any>): void {
    this._appVersionStoreService.version = data.headers.get('x-response-api-version') ?? '';
  }
}
